import {SagaIterator} from 'redux-saga';
import {put, call} from 'redux-saga/effects';
import requestHandler from '_services/api/axios-config';
import {transformNoteMedicationsData} from '_helpers/utils';
import {HttpMethods} from '_services/api/axios.types';
import * as schema from '_redux/schema';
import {normalize} from 'normalizr';
import store from '_redux/root-reducer';
import {toast} from 'react-toastify';
import {
  FetchPatientNotesStartAction,
  CreatePatientNoteStartAction,
  MigratePatientNotesStartAction,
} from './patients-notes-actions.types';
import {
  fetchPatientNotesSuccess,
  fetchPatientNotesFailure,
  createPatientNoteSuccess,
  clearPatientNotes,
} from './patients-notes.actions';
import {MedicationNameList} from './patients-notes.types';

export function* fetchPatientNotesSaga({
  payload: {userRole, patientEmail, numRequest, lastRequest},
}: FetchPatientNotesStartAction): SagaIterator<void> {
  try {
    const {
      data: {
        message: {notes, totalNotes},
      },
    } = yield call(requestHandler, {
      method: HttpMethods.GET,
      url: `/api/${userRole}/notes/${patientEmail}?numRequest=${numRequest}&lastRequest=${lastRequest}`,
    });

    if (Array.isArray(notes) && totalNotes) {
      const normalizedNotes = yield call(normalize, notes, schema.arrayOfNotes);
      yield put(fetchPatientNotesSuccess({...normalizedNotes, totalNotes}));
    } else {
      yield put(
        fetchPatientNotesSuccess({
          result: [],
          entities: {notes: {}},
          totalNotes: 0,
        }),
      );
    }
  } catch (error) {
    yield put(fetchPatientNotesFailure());
  }
}

export function* createPatientNotesSaga({
  payload: {
    chiefComplaint,
    patientNotes,
    plan,
    prescribeMedication,
    pharmacyService,
    medications,
    medicationList,
    date,
    patientEmail,
    closeModal,
    setSubmitting,
    resetForm,
    userRole,
    noteCreatorName,
    icdCodes,
  },
}: CreatePatientNoteStartAction): SagaIterator<void> {
  try {
    const {
      data: {
        message: {note},
      },
    } = yield call(requestHandler, {
      method: HttpMethods.POST,
      url: `/api/${userRole}/notes/${patientEmail}`,
      data: {
        chiefComplaint,
        patientNotes,
        plan,
        prescribeMedication,
        pharmacyService,
        noteCreatorName,
        medications: transformNoteMedicationsData(
          medications as MedicationNameList[],
          userRole,
          medicationList,
        ),
        date,
        icdCodes,
      },
    });

    yield call(resetForm);
    yield call(setSubmitting, false);
    const normalizedNotes = yield call(normalize, note, schema.note);

    yield put(
      createPatientNoteSuccess({
        ...normalizedNotes,
        totalNotes: store.getState().patients.current.notes.allIds.length + 1,
      }),
    );
    if (closeModal) yield call(closeModal);
  } catch (error) {
    yield call(setSubmitting, false);
  }
}

export function* migratePatientNotesSaga({
  payload: {_to, _from, closeModal, setSubmitting, resetForm, userRole},
}: MigratePatientNotesStartAction): SagaIterator<void> {
  try {
    yield call(requestHandler, {
      method: HttpMethods.PUT,
      url: `/api/${userRole}/notes/migrate/${_from}`,
      data: {
        _to,
      },
    });

    yield call(resetForm);
    yield call(setSubmitting, false);

    if (closeModal) yield call(closeModal);
    toast.success(`Notes Migrated Successfully to ${_to}`);
    yield put(clearPatientNotes());
  } catch (error) {
    yield call(setSubmitting, false);
    if (closeModal) yield call(closeModal);
  }
}
